<template>
  <div class="wrap">
    <div class="left">
      <div class="header-wrap">
        <img class="img-back"
             src="@/assets/icon_back.png"
             @click="$router.go(-1)" />
        <span class="font-size28-color333-weight600">邀请分享</span>
        <div class="img-back" />
      </div>
      <div class="share-way-wrap"
           v-for="(item, index) in shareWays"
           :key="index">
        <span class="font-size24-color333-weight600">{{  `方法${index+1}:${item.title}`  }}</span>
        <div class="share-content-wrap">
          <div class="share-content font-size24-color333-weight400">{{ item.shareContent }}</div>
          <div :id="`share-${index}`"
               class="btn-share font-size20-color2196F3-weight400"
               :data-clipboard-text="item.shareContent"
               @click="onCopy(index)">复制</div>
        </div>
      </div>
      <div class="share-right-rule">
        <div class="share-right-txt">诚挚邀请您加入我们的合伙人推广计划！您只需要将平台链接分享给身边需要的好友，即可获得对应比例的积分奖励，积分可兑换商品或进行提现哦！</div>
        <div class="share-right-txt">{{ shareRights }}</div>
      </div>
    </div>
    <div class="right">
      <span class="txt-invate font-size28-color333-weight600">邀请明细</span>
      <div class="score-overview">
        <div class="score-available">
          <div class="font-size20-color333-weight400">可兑换积分</div>
          <div class="font-size40-colorF44336-weight600">{{ canUseScore }}</div>
        </div>
        <div class="score-category-wrap">
          <div class="score-category-item"
               v-for="(item, index) in scoreCategorys"
               :key="index">
            <div class="font-size20-color666-weight400"
                 style="color:#999999">{{ item.title }}</div>
            <div class="font-size28-color666-weight600">{{ item.score }}</div>
          </div>
        </div>
        <div class="btn-score-wrap" v-if="false">
          <div class="btn-score-change font-size24-colorFF9800-weight400">积分兑换</div>
          <div class="btn-score-withdraw font-size24-colorFFF-weight400"
               @click="doWdithDraw">积分提现</div>
        </div>
      </div>
      <div class="score-detail-wrap"
           v-if="scoreList.length">
        <div class="detail-header-wrap">
          <div class="detail-header-item font-size20-color666-weight400">昵称</div>
          <div class="detail-header-item font-size20-color666-weight400">手机</div>
          <div class="detail-header-item font-size20-color666-weight400">积分</div>
          <div class="detail-header-item font-size20-color666-weight400">时间</div>
        </div>
        <div class="detail-header-wrap"
             v-for="(item,index) in scoreList"
             :key="index">
          <div class="detail-header-item font-size20-color333-weight400">{{ item.nickName }}</div>
          <div class="detail-header-item font-size20-color333-weight400">{{ item.mobile }}</div>
          <div class="detail-header-item font-size20-color333-weight400">{{ item.score }}</div>
          <div class="detail-header-item font-size20-color333-weight400">{{ item.time }}</div>
        </div>
      </div>
    </div>
    <WidthdrawDialog ref="dialogRef"></WidthdrawDialog>
  </div>
</template>

<script>
import { getScoreInfo } from '@/api/vip.js'
import Clipboard from 'clipboard';
import WidthdrawDialog from '@/views/vip/components/widthdrawDialog.vue'
export default {
  data () {
    return {
      shareWays: [
        {
          title: '把邀请链接发给好友',
          shareContent: ''
        },
        {
          title: '把邀请码发给好友',
          shareContent: ''
        }
      ],
      shareRights:
        `分享规则：
        1、分享您的邀请链接或邀请码给好友，当好友通过您的邀请链接/邀请码注册并且首次付费购买会员后，您将会获得好友付款金额的30%的作为积分奖励；
        2、好友后续分享自己的邀请链接或邀请码给其他人且成功付费，您均可获得付费金额5%的积分奖励。
        3、好友付款购买成功后，积分即可到账。到账30日后才可以提现奖励，发生退款后收益无效。积分奖励超过100元方可提现，积分提现时请核对好您支付宝账号信息，如因误填产生的收益损失，我司不承担任何责任。本次活动最终解释权归广东善利智能科技有限公司所有。`,
      canUseScore: 0,
      scoreCategorys: [
        {
          title: '待确定积分',
          score: 0
        },
        {
          title: '已兑换积分',
          score: 0
        },
        {
          title: '累计积分',
          score: 0
        }
      ],
      scoreList: []
    }
  },
  components: {
    WidthdrawDialog
  },
  mounted () {
    this.getCode()
    this.loadScoreInfo()
  },
  methods: {
    getCode () {
      this.$http({
        url: '/api/v1/user/invite_code',
        method: 'get',
        params: {

        }
      }).then(res => {
        this.shareWays[1].shareContent = res.data.invite_code
        var currentDomain = window.location.protocol + '//' + window.location.host;
        this.shareWays[0].shareContent = currentDomain + '/#/register?invite_code=' + res.data.invite_code;

      })
    },
    loadScoreInfo () {
      let that = this
      getScoreInfo().then(res => {
        let data = res.data
        // 可兑换积分
        that.canUseScore = data.redeemable_points
        // 待确定积分
        that.scoreCategorys[0].score = data.undetermined_points
        // 已兑换积分
        that.scoreCategorys[1].score = data.redeemed_points
        // 累计积分
        that.scoreCategorys[2].score = data.accumulated_points
      })
    },
    onCopy (id) {
      var clipboard = new Clipboard(`#share-${id}`);
      clipboard.on("success", (e) => {
        this.$message.success('复制成功')
        // 释放内存
        clipboard.destroy();
      });
    },
    doWdithDraw () {
      //可兑换积分大于100才能体现
      if (this.canUseScore > 100) {
        this.$refs.dialogRef.showDialog()
      } else {
        this.$message.error('可兑换积分大于100才能提现')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  min-height: 100%;
  display: flex;
  .left {
    display: flex;
    flex: 1;
    padding: 40px;
    flex-direction: column;
    background: #fff;
    gap: 40px;
    .header-wrap {
      display: flex;
      height: 80px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      .img-back {
        width: 48px;
        height: 48px;
      }
    }
    .share-way-wrap {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .share-content-wrap {
        display: flex;
        align-items: center;
        gap: 16px;
        .share-content {
          display: flex;
          width: 720px;
          height: 48px;
          padding: 0px 16px;
          align-items: center;
          gap: 8px;
          border-radius: 8px;
          border: 1px solid #eee;
          background: #fff;
        }
        .btn-share {
          display: flex;
          padding: 7px 24px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          align-self: stretch;
          border-radius: 8px;
          border: 1px solid #2196f3;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    .share-right-rule {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 8px;
      .share-right-txt {
        color: #666;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        // white-space: pre-wrap;
        white-space: pre-line;
      }
    }
  }
  .right {
    width: 39.56%;
    min-height: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fafafa;
    border-left: 1px solid #eee;
    .txt-invate {
      display: flex;
      height: 80px;
      justify-content: center;
      align-items: center;
    }
    .score-overview {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      padding: 24px;
      align-self: stretch;
      border-radius: 16px;
      background: #fff;
      box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.02);
      .score-available {
        display: flex;
        width: 100%;
        height: 160px;
        padding: 0px 24px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background: #fff9f0;
        box-sizing: border-box;
      }
      .score-category-wrap {
        width: 100%;
        display: flex;
        .score-category-item {
          height: 96px;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
      .btn-score-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 24px;
        align-self: stretch;
        .btn-score-change {
          display: flex;
          height: 56px;
          padding: 8px 28px;
          justify-content: center;
          align-items: center;
          gap: 6px;
          flex: 1 0 0;
          border-radius: 100px;
          border: 1px solid var(--Warning-color_06, #ff9800);
          &:hover {
            cursor: pointer;
          }
        }
        .btn-score-withdraw {
          display: flex;
          height: 56px;
          padding: 8px 28px;
          justify-content: center;
          align-items: center;
          gap: 6px;
          flex: 1 0 0;
          border-radius: 100px;
          background: var(--Warning-color_06, #ff9800);
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    .score-detail-wrap {
      flex: 1;
      display: flex;
      padding: 0px var(--spacer-24_spacer_m, 24px);
      flex-direction: column;
      align-items: center;
      flex: 1 0 0;
      margin-top: 32px;
      align-self: stretch;
      border-radius: 16px;
      background: #fff;
      box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.02);
      .detail-header-wrap {
        display: flex;
        padding: 16px 0px;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        .detail-header-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          flex: 1 0 0;
        }
      }
    }
  }
}
.font-size28-color333-weight600 {
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 42px;
  color: #333;
}
.font-size24-color333-weight400 {
  color: #333;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
}
.font-size20-color2196F3-weight400 {
  color: #2196f3;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
.font-size24-color333-weight600 {
  color: #333;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px; /* 150% */
}
.font-size20-color666-weight400 {
  color: #666;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
.font-size40-colorF44336-weight600 {
  color: #f44336;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px; /* 150% */
}
.font-size24-colorFF9800-weight400 {
  color: var(--Warning-color_06, #ff9800);
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 150% */
}
.font-size24-colorFFF-weight400 {
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 150% */
}
.font-size20-color666-weight400 {
  color: var(--light-666666, var(--neutral-color-666666, #666));
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
}
.font-size28-color666-weight600 {
  color: var(--neutral-color-333333, #333);
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 42px; /* 150% */
}
.font-size20-color333-weight400 {
  color: var(--light-333333, var(--neutral-color-333333, #333));
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
}
</style>