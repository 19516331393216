<template>
  <div class="dialog-wrap">
    <el-dialog :visible.sync="dialogStatus"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               @close="hideDialog">
      <div class="top">
        <div class="title">绑定账号提现</div>
        <div class="input-item-wrap">
          <span class="label">真实姓名</span>
          <el-input v-model="form.real_name"></el-input>
        </div>
        <span class="tip-input">支付宝账号真实姓名</span>
        <div class="input-item-wrap">
          <span class="label">支付宝账号</span>
          <el-input v-model="form.account"></el-input>
        </div>
        <div class="input-item-wrap">
          <span class="label">提现金额 (元)</span>
          <el-input v-model="form.integral"></el-input>
        </div>
      </div>
      <div class="horizaton-line"></div>
      <div class="bottom">
        <div class="btn-cancel"
             @click="doCancel">取消</div>
        <div class="vertical-line"></div>
        <div class="btn-sure"
             @click="doWidthDraw">提现</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  data () {
    return {
      dialogStatus: false,
      form: {
        real_name: '',
        account: '',
        integral: '',
      }
    }
  },
  methods: {
    hideDialog () {
      this.dialogStatus = false
    },
    showDialog () {
      this.dialogStatus = true
    },
    doCancel () {
      this.hideDialog()
    },
    doWidthDraw () {
    
        this.$http({
          url: '/api/v1/pay/extract',
          method: 'post',
          data: { ...this.form }
        }).then(res => {
          console.log(res)

        })
     

    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  width: 720px;
  background: #fff;
  border-radius: 32px;
}
::v-deep .el-dialog__header,
::v-deep .el-dialog__footer {
  display: none;
}
::v-deep .el-dialog__body {
  display: flex;
  flex-direction: column;
  padding: 0px;
}
::v-deep .el-input__inner {
  display: flex;
  width: 480px;
  height: 60px;
  padding: 0px 16px;
  align-items: center;
  color: #333;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  border-radius: 8px;
  border: 1px solid #ccc;
  background: #fff;
}
::v-deep .el-input {
  width: 480px;
}
.top {
  display: flex;
  margin: 32px;
  flex-direction: column;
  align-self: stretch;
  .title {
    color: #333;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
  }
  .input-item-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    gap: 16px;
    margin-top: 24px;
    .label {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      color: #333;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px;
    }
  }
  .tip-input {
    color: #666;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    margin-top: 8px;
    padding-left: 176px;
  }
}
.horizaton-line {
  width: 100%;
  height: 1px;
  background: #eee;
}
.bottom {
  display: flex;
  height: 64px;
  .btn-cancel,
  .btn-sure {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    color: #333;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    &:hover {
      cursor: pointer;
    }
  }
  .btn-sure {
    color: #2196f3;
  }
  .vertical-line {
    width: 1px;
    height: 100%;
    background: #eee;
  }
}
</style>